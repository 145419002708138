<template>
  <modal
    height="auto"
    :name="modalName"
    @before-open="onBeforeOpen"
  >
    <div class="modal-body">
      <heading level="3">
        Введите реквизиты счета
      </heading>
      <div class="modal-body__descr">
        Данный способ оплаты доступен только для юридических лиц и ИП
      </div>

      <x-loader v-if="isLoading" />

      <requisites-form
        v-else
        v-model="form"
        submit-button-title="Выставить счет"
        class="modal-body__form scrollbar_primary"
        :disable-amount="disableAmount"
        @submit="onSubmit"
        @cancel="$modal.hide(modalName)"
        @client-change="onClientChange"
      />

      <close-button @click="$modal.hide(modalName)" />
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import RequisitesForm from '@/components/Settings/Finances/PaymentAccounts/RequisitesForm.vue'
import Heading from '@/components/Common/Heading.vue'
import { mapActions } from 'vuex'
import { getPaymentAccountByClient, updatePaymentAccount } from '@/api/default/modules/settings/finances'

export default {
  name: 'TariffPaymentAccountModal',
  components: {
    RequisitesForm,
    CloseButton,
    Heading
  },

  data () {
    return {
      modalName: 'tariff-payment-account-modal',
      form: {
        client: null,
        organization_name: null,
        legal_address: null,
        inn: null,
        kpp: null,
        account_number: null,
        correspondent_account: null,
        bik: null,
        phone: null,
        fax: null,
        email: null,
        amount: 0
      },
      isLoading: false,
      paymentAccountId: null,
      tariffId: null,
      tariffTerm: null,
      disableAmount: false
    }
  },

  methods: {
    ...mapActions('settings/finances', ['createPaymentAccount', 'downloadPaymentDoc']),
    ...mapActions('settings/permissions', ['getClient']),

    async onSubmit () {
      try {
        this.isLoading = true

        if (this.paymentAccountId !== null) {
          await updatePaymentAccount(this.paymentAccountId, {
            ...this.form,
            client: this.form.client.id
          })
          await this.createInvoice(this.paymentAccountId)
        } else {
          const response = await this.createPaymentAccount({
            ...this.form,
            client: this.form.client.id
          })
          await this.createInvoice(response.data.id)
        }

        this.$emit('success')
        this.$modal.hide(this.modalName)
      } finally {
        this.isLoading = false
      }
    },

    onBeforeOpen (e) {
      this.form.amount = e.params.amount
      this.tariffId = e.params.tariffId
      this.tariffTerm = e.params.tariffTerm
      this.disableAmount = e.params.disableAmount
    },

    async createInvoice (accountId) {
      await this.downloadPaymentDoc({
        amount: this.form.amount,
        payment_account: accountId,
        ...(this.tariffId !== null && {
          tariff_id: this.tariffId,
          term: this.tariffTerm
        })
      })
    },

    async onClientChange () {
      try {
        this.isLoading = true

        const response = await getPaymentAccountByClient({ client_id: this.form.client.id })

        if (response.data?.data?.[0]) {
          this.paymentAccountId = response.data.data[0].id

          for (const key of Object.keys(this.form)) {
            if (key !== 'client' && key !== 'amount') {
              this.form[key] = response.data.data[0][key]
            }
          }
        } else {
          this.paymentAccountId = null
        }

        if (this.paymentAccountId === null) {
          const clientData = await this.getClient(this.form.client.id)

          this.form = {
            client: this.form.client,
            correspondent_account: null,
            fax: null,
            email: null,
            bik: clientData.bic,
            inn: clientData.inn,
            kpp: clientData.kpp,
            legal_address: clientData.legal_address,
            organization_name: clientData.legal_name,
            account_number: clientData.payment_account,
            phone: clientData.phone,
            amount: this.form.amount
          }
        }
      } catch (err) {

      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-body
  padding 32px

  &__form
    display block
    max-height 500px
    overflow-y scroll
    padding-right 10px
  &__descr
    margin-bottom 10px
</style>
