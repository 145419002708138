<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <x-loader v-if="isLoading" />
    <seller-form
      v-else
      class="requisites-form"
      @submit="handleSubmit(onSubmit)"
    >
      <fieldset class="form-fields-group">
        <ul class="form-fields-list">
          <li class="form-fields-list__item">
            <x-form-item
              name="Клиент"
              label="Клиент"
              rules="required"
            >
              <x-select
                track-by="id"
                label="name"
                :value="value.client"
                :options="clientList"
                :limit="1"
                :allow-empty="false"
                placeholder="Выберите клиента"
                @select="onSelectClient($event)"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ validationData }"
              name="Наименование организации"
              label="Наименование организации"
              rules="required"
            >
              <x-input
                v-bind="validationData"
                :value="value.organization_name"
                placeholder="Введите наименование организации"
                @input="value => onInput('organization_name', value)"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ validationData }"
              name="Юридический адрес компании"
              label="Юридический адрес компании"
              rules="required"
            >
              <x-input
                v-bind="validationData"
                :value="value.legal_address"
                placeholder="Введите юридический адрес компании"
                @input="value => onInput('legal_address', value)"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ validationData }"
              name="ИНН"
              label="ИНН"
              rules="required|inn"
            >
              <x-input
                v-bind="validationData"
                :value="value.inn"
                placeholder="Введите ИНН"
                @input="onInnInput"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ validationData }"
              name="КПП"
              label="КПП"
              :rules="isKppFieldDisabled ? '' : 'required|length:9'"
            >
              <x-input
                v-bind="validationData"
                :value="value.kpp"
                :disabled="isKppFieldDisabled"
                placeholder="Введите КПП"
                @input="value => onInput('kpp', value)"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="validationData"
              name="Номер расчетного счета"
              label="Номер расчетного счета"
              rules="required|entityOnly|digits:20"
            >
              <x-input
                v-bind="validationData"
                :value="value.account_number"
                placeholder="Введите номер расчетного счета"
                @input="value => onInput('account_number', value)"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ validationData }"
              name="Номер корреспондентского счета"
              label="Номер корреспондентского счета"
              rules="required|digits:20"
            >
              <x-input
                v-bind="validationData"
                :value="value.correspondent_account"
                placeholder="Введите номер корреспондентского счета"
                @input="value => onInput('correspondent_account', value)"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ validationData }"
              name="БИК"
              label="БИК"
              rules="required|digits:9"
            >
              <x-input
                v-bind="validationData"
                :value="value.bik"
                placeholder="Введите БИК"
                @input="value => onInput('bik', value)"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <phone-field
              :value="value.phone"
              @input="value => onInput('phone', value)"
            />
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ validationData }"
              name="Факс"
              label="Факс"
            >
              <x-input
                v-bind="validationData"
                :value="value.fax"
                placeholder="Введите факс"
                @input="value => onInput('fax', value)"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ validationData }"
              name="Email"
              label="Email"
              rules="required|email"
            >
              <x-input
                v-bind="validationData"
                :value="value.email"
                placeholder="Введите email"
                @input="value => onInput('email', value)"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ validationData }"
              name="Сумма"
              label="Введите сумму"
              rules="required|isNumber|min_value:1"
            >
              <x-input
                v-bind="validationData"
                :value="value.amount"
                :disabled="disableAmount"
                placeholder="Сумма, руб"
                @input="onAmountInput"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <div class="requisites-form__actions">
              <x-btn
                type="button"
                color="gray"
                @click="$emit('cancel')"
              >
                Отменить
              </x-btn>

              <x-btn type="submit">
                <slot name="btn">
                  {{ submitButtonTitle }}
                </slot>
              </x-btn>
            </div>
          </li>
        </ul>
      </fieldset>
    </seller-form>
  </validation-observer>
</template>

<script>
import SellerForm from '@/components/Interface/Form.vue'
import PhoneField from '@/components/Interface/PhoneField.vue'
import { createNamespacedHelpers } from 'vuex'
import { extend } from 'vee-validate'
extend('entityOnly', val => {
  return (val.slice(0, 5) === '40702' || val.slice(0, 5) === '40802') || 'Данный способ оплаты доступен только для юридических лиц и ИП'
})

const { mapActions, mapState } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    SellerForm,
    PhoneField
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    submitButtonTitle: {
      type: String,
      default: ''
    },
    disableAmount: Boolean
  },

  data: () => ({
    isLoading: false
  }),

  computed: {
    ...mapState(['clientList']),

    isKppFieldDisabled () {
      return this.value.inn?.length !== 10
    }
  },

  async created () {
    if (this.clientList.length) return

    this.isLoading = true
    await this.getClientList()
    this.isLoading = false

    if (this.clientList?.length) {
      this.onSelectClient(this.clientList[0])
    }
  },

  methods: {
    ...mapActions(['getClientList', 'getClient']),

    onInput (field, value) {
      this.$emit('input', {
        ...this.value,
        [field]: value
      })
    },

    onSubmit () {
      this.$emit('submit', this.value)
    },

    async onSelectClient (client) {
      this.onInput('client', client)

      this.$emit('client-change')

      // const clientData = await this.getClient(client.id)
      //
      // this.$emit('input', {
      //  ...this.value,
      //  bik: clientData.bic,
      //  inn: clientData.inn,
      //  kpp: clientData.kpp,
      //  legal_address: clientData.legal_address,
      //  organization_name: clientData.legal_name,
      //  phone: clientData.phone
      // })
    },

    async onInnInput (value) {
      this.onInput('inn', value)
      if (this.value.inn !== 10) {
        await this.$nextTick()
        this.onInput('kpp', '')
      }
    },

    onAmountInput (value) {
      this.onInput('amount', value.replace(/,/g, '.'))
    }
  }
}
</script>

<style lang="stylus" scoped>
.requisites-form
  &__actions
    display flex
    align-items center
    justify-content flex-end
</style>
