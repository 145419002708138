<template>
  <x-dialog
    size="s"
    no-actions
  >
    <div class="tariff-invoice-done-modal">
      <heading
        level="3"
        class="tariff-pay-modal__title"
      >
        Ваш счет сформирован. Оплатите его и получите доступ к платформе {{ companyDetails.platformName }}
      </heading>
      <div class="tariff-invoice-done__buttons">
        <x-btn
          color="primary"
          @click="goToFinanceHistory"
        >
          Перейти в раздел финансы
        </x-btn>
      </div>
    </div>
  </x-dialog>
</template>

<script>
import Heading from '@/components/Common/Heading'
import companyDetails from '@/plugins/companyDetails'

export default {
  name: 'TariffInvoiceDone',
  components: { Heading },
  data: () => ({
    companyDetails
  }),
  methods: {
    goToFinanceHistory () {
      this.$router.push('/settings/finances?tab=history')
    }
  }
}
</script>

<style lang="stylus">
.tariff-invoice-done-modal
  text-align center

  &__buttons
    margin-top 20px

</style>
