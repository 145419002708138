var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.isLoading)?_c('x-loader'):_c('seller-form',{staticClass:"requisites-form",on:{"submit":function($event){return handleSubmit(_vm.onSubmit)}}},[_c('fieldset',{staticClass:"form-fields-group"},[_c('ul',{staticClass:"form-fields-list"},[_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"Клиент","label":"Клиент","rules":"required"}},[_c('x-select',{attrs:{"track-by":"id","label":"name","value":_vm.value.client,"options":_vm.clientList,"limit":1,"allow-empty":false,"placeholder":"Выберите клиента"},on:{"select":function($event){return _vm.onSelectClient($event)}}})],1)],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"Наименование организации","label":"Наименование организации","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationData = ref.validationData;
return [_c('x-input',_vm._b({attrs:{"value":_vm.value.organization_name,"placeholder":"Введите наименование организации"},on:{"input":function (value) { return _vm.onInput('organization_name', value); }}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"Юридический адрес компании","label":"Юридический адрес компании","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationData = ref.validationData;
return [_c('x-input',_vm._b({attrs:{"value":_vm.value.legal_address,"placeholder":"Введите юридический адрес компании"},on:{"input":function (value) { return _vm.onInput('legal_address', value); }}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"ИНН","label":"ИНН","rules":"required|inn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationData = ref.validationData;
return [_c('x-input',_vm._b({attrs:{"value":_vm.value.inn,"placeholder":"Введите ИНН"},on:{"input":_vm.onInnInput}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"КПП","label":"КПП","rules":_vm.isKppFieldDisabled ? '' : 'required|length:9'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationData = ref.validationData;
return [_c('x-input',_vm._b({attrs:{"value":_vm.value.kpp,"disabled":_vm.isKppFieldDisabled,"placeholder":"Введите КПП"},on:{"input":function (value) { return _vm.onInput('kpp', value); }}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"Номер расчетного счета","label":"Номер расчетного счета","rules":"required|entityOnly|digits:20"},scopedSlots:_vm._u([{key:"default",fn:function(validationData){return [_c('x-input',_vm._b({attrs:{"value":_vm.value.account_number,"placeholder":"Введите номер расчетного счета"},on:{"input":function (value) { return _vm.onInput('account_number', value); }}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"Номер корреспондентского счета","label":"Номер корреспондентского счета","rules":"required|digits:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationData = ref.validationData;
return [_c('x-input',_vm._b({attrs:{"value":_vm.value.correspondent_account,"placeholder":"Введите номер корреспондентского счета"},on:{"input":function (value) { return _vm.onInput('correspondent_account', value); }}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"БИК","label":"БИК","rules":"required|digits:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationData = ref.validationData;
return [_c('x-input',_vm._b({attrs:{"value":_vm.value.bik,"placeholder":"Введите БИК"},on:{"input":function (value) { return _vm.onInput('bik', value); }}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('phone-field',{attrs:{"value":_vm.value.phone},on:{"input":function (value) { return _vm.onInput('phone', value); }}})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"Факс","label":"Факс"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationData = ref.validationData;
return [_c('x-input',_vm._b({attrs:{"value":_vm.value.fax,"placeholder":"Введите факс"},on:{"input":function (value) { return _vm.onInput('fax', value); }}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"Email","label":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationData = ref.validationData;
return [_c('x-input',_vm._b({attrs:{"value":_vm.value.email,"placeholder":"Введите email"},on:{"input":function (value) { return _vm.onInput('email', value); }}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"name":"Сумма","label":"Введите сумму","rules":"required|isNumber|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationData = ref.validationData;
return [_c('x-input',_vm._b({attrs:{"value":_vm.value.amount,"disabled":_vm.disableAmount,"placeholder":"Сумма, руб"},on:{"input":_vm.onAmountInput}},'x-input',validationData,false))]}}])})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('div',{staticClass:"requisites-form__actions"},[_c('x-btn',{attrs:{"type":"button","color":"gray"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Отменить ")]),_c('x-btn',{attrs:{"type":"submit"}},[_vm._t("btn",function(){return [_vm._v(" "+_vm._s(_vm.submitButtonTitle)+" ")]})],2)],1)])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }